import React from "react"
import { Link as Link1 } from "gatsby"
import { Link as Link2 } from 'react-scroll'


export const H2 = props => <h2 className="mb-10 px-4 leading-snug font-semibold text-2xl md:text-3xl text-center" id={props.id}>{props.children}</h2>
export const H3 = props => <h2 className="my-4 leading-snug font-semibold text-xl text-center md:text-left" id={props.id}>{props.children}</h2>
export const FigureRight = props => <figure className="hidden md:block w-1/2 float-right my-4 -mr-32 ml-4 rounded overflow-hidden" {...props} />
export const SectionOdd = props => <section className="w-full py-24 overflow-hidden" {...props} />
export const SectionEven = props => <section className="w-full py-24 overflow-hidden bg-gray-500 bg-opacity-15 md:rounded" {...props} />
export const Inner = props => <div className="block max-w-screen-md mt-10 px-4 md:px-8 lg:px-0 mx-auto" {...props} />
export const InnerTight = props => <div className="block w-full md:w-4/5 mx-auto" {...props} />
export const InnerFigure = props => <figure className="block md:inline-block mx-auto md:mx-4 my-6 align-top w-11/12 sm:w-1/2 md:w-4/12 text-center md:text-left rounded overflow-hidden" {...props} />
export const InnerDiv = props => <div className="inline-block w-full md:w-7/12 px-0 md:pl-4" {...props} />
export const Link = props => <Link1 className="text-blue-500 no-underline hover:underline" {...props} />
export const List = props => <ul className="my-4 overflow-hidden border-b border-gray-500 border-opacity-50  text-sm" {...props} />
export const ListItemLink = props =><li><Link className="block py-2 px-3 md:px-6 border-t border-gray-500 border-opacity-50 text-sm text-blue-500 no-underline hover:underline leading-snug" {...props} /></li>
export const ListItem = props =><li><p className="block py-2 px-3 md:px-6 border-t border-gray-500 border-opacity-50  text-sm leading-snug" {...props} /></li>
export const Scroll = props => <Link2 className="text-blue-500 no-underline hover:underline cursor-pointer" spy={false} smooth={true} offset={-50} duration={200} {...props} />
export const ButtonCenter = props => <div className="my-6 text-center"><a className="inline-block py-3 px-10 bg-gray-800 text-white rounded shadow-xl hover:bg-gray-700" {...props} target="_brank" style={{display: "inline-block"}}>{}</a></div>
