import React from "react"

const Header = ({ pageTitle, children }) => (
  <header className="pt-36">
    <h1 className="text-4xl md:text-6xl font-bold text-center leading-snug">{pageTitle}</h1>
    <hr className="w-36 my-6 mx-auto border-gray-300" />
    <p className="pt-4 text-center mx-8 md:mx-0 leading-snug">{children}</p>
  </header>
)

export default Header
