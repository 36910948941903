import React from "react"
import Layout from "../components/ui/layout"
import Seo from '../components/ui/seo'
import Header from '../components/page/header'
import {
  ButtonCenter,
} from '../components/page/category-design'

const pageTitle = "お問い合わせ"

const Contact = ({ location }) => (
  <Layout>
    <Seo
      pageTitle={pageTitle}
      pageDescription="著者へのお問い合わせはこちら"
      pageUrl={location.pathname}
      isNoIndex={true}
    />
    <Header pageTitle={pageTitle} >
      著者へのお問い合わせやご感想
    </Header>
    <main className="pt-36">
      <div className="block max-w-screen-sm px-8 md:px-0 mx-auto">
      <p className="my-4 text-center">著者へのお仕事のご相談、その他のお問い合わせは、
      <br/>以下のいずれかにお願いいたします。</p>
        <div className="my-10"><ButtonCenter href="https://forms.gle/S9X67MMrMd4PYbuH7" target="_brank" >フォームを開く</ButtonCenter></div>
      </div>
      <p className="my-4 text-center">contact@abbamboo.com
      <br/>Twitter（<a href="https://twitter.com/u_ya41216132" target="_brank">@u_ya41216132</a>）</p>
    </main>
  </Layout>
)

export default Contact
